/* eslint-disable @repo/internal/react/no-unsafe-overrides */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useContext } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import Heading from '@atlaskit/heading';
import { Stack, Text } from '@atlaskit/primitives';
import { type Groups, token } from '@atlaskit/tokens';
import { getCSSCustomProperty } from '@atlaskit/tokens/token-ids';

import { TokenNameSyntaxContext } from '../../token-explorer/components/token-name-syntax-context';
import mergedTokens from '../../token-explorer/merged-tokens';
import { getBoxShadow, getTextContrast } from '../../utils';
import type { Pairings as PairingsType } from '../data/types';

import CopyPasteBlock from './copy-paste-block';
import Pairings from './pairings';

const cardsWrapperStyles = css({
	display: 'flex',
	margin: `${token('space.100', '8px')} ${token('space.0', '0px')} ${token('space.100', '8px')}`,
});

const tokenNameStyled = css({
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
	background: token('color.background.neutral'),
	borderRadius: token('border.radius', '3px'),
	color: token('color.text'),
	cursor: 'pointer',
	font: token('font.code'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		verticalAlign: 'middle',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		background: token('color.background.neutral.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active': {
		background: token('color.background.neutral.pressed'),
	},
});

const colorBlockStyles = css({
	minWidth: '6rem',
	borderRadius: token('border.radius', '3px'),
	cursor: 'pointer',
	font: token('font.body'),
	paddingBlock: token('space.025', '2px'),
	textAlign: 'center',
});

const opacityTokenBlockStyles = css({
	position: 'relative',
	backgroundColor: token('elevation.surface'),
	backgroundImage: `linear-gradient(
          45deg,
          ${token('elevation.surface.sunken')} 25%,
          transparent 25%
        ),
        linear-gradient(
          135deg,
          ${token('elevation.surface.sunken')} 25%,
          transparent 25%
        ),
        linear-gradient(
          45deg,
          transparent 75%,
          ${token('elevation.surface.sunken')} 75%
        ),
        linear-gradient(
          135deg,
          transparent 75%,
          ${token('elevation.surface.sunken')} 75%
        )`,
	backgroundPosition: '0px 0px, 0.5rem 0px, 0.5rem -0.5rem, 0px 0.5rem',
	backgroundSize: '1rem 1rem',
	color: token('color.text'),
	overflow: 'hidden',
});

const opacityMaskStyles = css({
	width: '100%',
	height: '100%',
	position: 'absolute',
	backgroundColor: token('color.text'),
	insetBlockStart: token('space.0', '0px'),
	insetInlineStart: token('space.0', '0px'),
});

const getShadowBlockStyles = (value: any) => ({
	height: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	backgroundColor: 'white',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	color: 'black',
	boxShadow: getBoxShadow(value as any),
});

const getBaseTokenBlockStyles = (value: string) => ({
	background: value,
	color: getTextContrast(value),
});

const valueCardStyles = css({
	minWidth: token('space.600', '48px'),
	padding: token('space.025', '2px'),
	background: token('elevation.surface.raised'),
	borderRadius: token('border.radius', '3px'),
	boxShadow: token('elevation.shadow.raised'),
	marginInlineEnd: token('space.050', '4px'),
});

const themeTextStyles = css({
	margin: `${token('space.0', '0px')} ${token('space.150', '12px')}`,
	textAlign: 'center',
});

const ValueCard = ({
	theme,
	group,
	baseToken,
	value,
}: {
	theme: 'light' | 'dark';
	group: Groups;
	baseToken: string;
	value: string | number;
}) => {
	return (
		<div css={valueCardStyles}>
			{group === 'shadow' ? (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				<div style={getShadowBlockStyles(value)} />
			) : group === 'opacity' ? (
				<CopyPasteBlock
					text={baseToken}
					renderWrapper={(children) => (
						<div css={[colorBlockStyles, opacityTokenBlockStyles]}>
							{children}
							<div css={opacityMaskStyles} style={{ opacity: value }} />
						</div>
					)}
				/>
			) : (
				<CopyPasteBlock
					text={baseToken}
					renderWrapper={(children) => (
						<div
							css={[colorBlockStyles]}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={getBaseTokenBlockStyles(value as string)}
						>
							{children}
						</div>
					)}
				/>
			)}
			<p css={themeTextStyles}>{theme === 'light' ? 'Light value' : 'Dark value'}</p>
		</div>
	);
};

const mergedTokenMap = Object.fromEntries(mergedTokens.map((t) => [t.cleanName, t]));

/**
 * __TokenItem__
 *
 * A suggested token item on the result panel.
 *
 */
const TokenItem = ({ tokenName, pairings }: { tokenName: string; pairings?: PairingsType }) => {
	const isRadiusToken = tokenName.startsWith('border.radius');
	const { syntax } = useContext(TokenNameSyntaxContext);

	const formattedName = syntax === 'css-var' ? getCSSCustomProperty(tokenName) : tokenName;

	const token = mergedTokenMap[tokenName];

	if (isRadiusToken) {
		return (
			<Stack space="space.200" alignInline="start">
				<CopyPasteBlock
					text={formattedName}
					renderWrapper={(children) => <code css={tokenNameStyled}>{children}</code>}
				/>
				<Stack space="space.100">
					<Heading size="xsmall" as="h5">
						Description
					</Heading>
					<Text>{token.attributes.description}</Text>
				</Stack>
			</Stack>
		);
	}

	const lightTokenRaw = token;
	// fragile
	const darkTokenRaw = token.darkToken!;

	const {
		value: lightValue,
		original: { value: lightBaseToken },
		attributes: { group, description },
	} = lightTokenRaw;
	const {
		value: darkValue,
		original: { value: darkBaseToken },
	} = darkTokenRaw;

	const tokenValue = (theme: 'light' | 'dark'): string => {
		let value;
		if (group === 'shadow') {
			value = getBoxShadow((theme === 'light' ? lightValue : darkValue) as any);
		} else {
			value = (theme === 'light' ? lightBaseToken : darkBaseToken) as string;
		}
		return value;
	};

	return (
		<Stack space="space.200" alignInline="start">
			<CopyPasteBlock
				text={formattedName}
				renderWrapper={(children) => <code css={tokenNameStyled}>{children}</code>}
			/>
			<div css={cardsWrapperStyles}>
				<ValueCard
					theme="light"
					group={group as Groups}
					baseToken={tokenValue('light')}
					value={lightValue}
				/>
				<ValueCard
					theme="dark"
					group={group as Groups}
					baseToken={tokenValue('dark')}
					value={darkValue}
				/>
			</div>
			<Stack space="space.100">
				<Heading size="xsmall">Description</Heading>
				<Text>{description}</Text>
			</Stack>
			{pairings && (
				<Stack space="space.100">
					{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<Heading size="xsmall" as="h5">
						Recommended pairings
					</Heading>
					<Pairings pairings={pairings} />
				</Stack>
			)}
		</Stack>
	);
};

export default TokenItem;
